'use client';
import { useState, useEffect } from 'react';
import Image from 'next/image';
import { Button } from 'flowbite-react';

const Wetech = () => {
  return (
    <div>
      <section className="bg-sky-50 py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-10">
            <h2 className="font-bold text-black text-3xl lg:text-4xl my-3">
              How We Teach
            </h2>
            <p className="font-semibold text-red-600 text-base md:text-lg uppercase">
              Empowering Minds with the World’s Largest Career-Oriented Courses
            </p>
            <p className="text-base md:text-lg font-medium mt-4 leading-relaxed">
              At <span className="font-bold">Walkin Educate</span>, founded by{' '}
              <strong>Ravi Shripal Jain</strong> in 2006, we transform education
              with innovative training in
              <strong>
                {' '}
                Graphic Design, Web Development, Animation, VFX, Interior
                Design, Digital Marketing, Photography,
              </strong>{' '}
              and beyond. Join us to build a creative, future-ready career.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 lg:gap-8 items-center justify-between">
            {/* Infrastructure Card */}
            <div className="bg-white shadow-2xl border-b-8 border-orange-600 hover:scale-105 transition-transform">
              <Image
                alt="World-Class Infrastructure"
                src="/images/services1.jpg"
                className="object-cover"
                width={467}
                height={650}
              />
              <div className="p-4 md:p-6">
                <h5 className="text-black font-bold text-lg">Infrastructure</h5>
                <p className="mt-2 text-gray-700 h-20">
                  Cutting-edge facilities designed to spark creativity and
                  enhance learning experiences.
                </p>
                <p className="mt-4">
                  <a
                    href="/infrastructure"
                    className="btn-theme text-sm rounded-full px-6 py-2 lg:py-3 bg-orange-600 text-white hover:bg-orange-700 transition-all"
                  >
                    Read More
                  </a>
                </p>
              </div>
            </div>

            {/* Student Zone Card */}
            <div className="bg-white shadow-2xl border-b-8 border-blue-800 hover:scale-105 transition-transform">
              <Image
                alt="Student Zone"
                src="/images/services2.jpg"
                className="object-cover"
                width={467}
                height={650}
              />
              <div className="p-4 md:p-6">
                <h5 className="text-black font-bold text-lg">Student Zone</h5>
                <p className="mt-2 text-gray-700  h-20">
                  A vibrant community for collaboration, growth, and excellence
                  in every field.
                </p>
                <p className="mt-4">
                  <a
                    href="/students-zone"
                    className="btn-theme text-sm rounded-full px-6 py-2 lg:py-3 bg-blue-800 text-white hover:bg-blue-900 transition-all"
                  >
                    Read More
                  </a>
                </p>
              </div>
            </div>

            {/* Events & Awards Card */}
            <div className="bg-white shadow-2xl border-b-8 border-orange-600 hover:scale-105 transition-transform">
              <Image
                alt="Event & Awards"
                src="/images/services3.jpg"
                className="object-cover"
                width={467}
                height={650}
              />
              <div className="p-4 md:p-6">
                <h5 className="text-black font-bold text-lg">Event & Awards</h5>
                <p className="mt-2 text-gray-700  h-20">
                  Honoring milestones and achievements with exciting and
                  memorable celebrations.
                </p>
                <p className="mt-4">
                  <a
                    href="/events-and-awards"
                    className="btn-theme text-sm rounded-full px-6 py-2 lg:py-3 bg-orange-600 text-white hover:bg-orange-700 transition-all"
                  >
                    Read More
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Wetech;
