import axios from 'axios';

// Set the API base URL for course categories
const API_COURSE_CATEGORIES_BASE_URL = `${
  process.env.NEXT_PUBLIC_API_URL || 'https://statamic.walkineducate.com'
}/api/courses-categories`;

/**
 * Fetch all course categories.
 * @returns {Promise<object[]>} - Returns an array of course categories.
 */
export const getAllCourseCategories = async () => {
  try {
    const response = await axios.get(API_COURSE_CATEGORIES_BASE_URL);

    if (response.data.success) {
      return response.data.data; // Return the categories
    }

    throw new Error('Failed to fetch course categories');
  } catch (error) {
    console.error('Error fetching course categories:', error);
    return [];
  }
};

/**
 * Fetch a single course category by slug with its related courses.
 * @param {string} slug - The slug of the course category.
 * @returns {Promise<object|null>} - Returns the category with related courses.
 */
export const getCourseCategoryBySlug = async (slug) => {
  try {
    // Fetch the category details (should include courses from Laravel API)
    const response = await axios.get(
      `${API_COURSE_CATEGORIES_BASE_URL}/${slug}`
    );

    if (response.data.success) {
      return response.data.data; // API should return courses inside the category response
    }

    throw new Error('Category not found');
  } catch (error) {
    console.error(`Error fetching course category with slug ${slug}:`, error);
    return null;
  }
};

// category on home page
export const getHomepageCourseCategories = async () => {
  try {
    const response = await axios.get(API_COURSE_CATEGORIES_BASE_URL);

    if (response.data.success) {
      return response.data.data.slice(0, 6); // Limit to 5 categories
    }

    throw new Error('Failed to fetch course categories');
  } catch (error) {
    console.error('Error fetching course categories:', error);
    return [];
  }
};
