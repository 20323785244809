import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/walkineducate.com/next.js/src/app/Counter.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/walkineducate.com/next.js/src/app/Courses.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/walkineducate.com/next.js/src/app/Faculty.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/walkineducate.com/next.js/src/app/Infobox.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/walkineducate.com/next.js/src/app/Intro.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/walkineducate.com/next.js/src/app/Slider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/walkineducate.com/next.js/src/app/Testimonials.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/walkineducate.com/next.js/src/app/Wetech.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/walkineducate.com/next.js/src/app/Whychoose.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/walkineducate.com/next.js/src/components/LatestCourses.js");
