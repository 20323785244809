'use client';
import { useState, useEffect } from 'react';
import Image from 'next/image';

const Counter = () => {
  const [studentsTrained, setStudentsTrained] = useState(0);
  const [branches, setBranches] = useState(0);
  const [placements, setPlacements] = useState(0);
  const [hasCounted, setHasCounted] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasCounted) {
          startCounting();
          setHasCounted(true);
        }
      },
      { threshold: 0.5 }
    );

    const section = document.getElementById('counter-section');
    if (section) observer.observe(section);

    return () => observer.disconnect();
  }, [hasCounted]);

  const startCounting = () => {
    let trained = 0;
    let branch = 0;
    let placement = 0;

    const interval = setInterval(() => {
      trained += 50;
      branch += 1;
      placement += 100;

      setStudentsTrained((prev) => (prev < 1000 ? trained : 1000));
      setBranches((prev) => (prev < 14 ? branch : 14));
      setPlacements((prev) => (prev < 2500 ? placement : 2500));

      if (trained >= 1000 && branch >= 14 && placement >= 2500) {
        clearInterval(interval);
      }
    }, 50);
  };

  return (
    <div>
      <section id="counter-section">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-0 items-stretch justify-between text-center text-white">
            <div className="bg-blue-800 p-4 lg:p-8">
              <div className="flex items-center justify-center mb-2">
                <Image
                  src="/images/ico-train.png"
                  className="object-cover mr-2"
                  alt="logo1"
                  width={58}
                  height={58}
                />
                <div className="text-3xl md:text-4xl lg:text-5xl font-bold mt-2">
                  {studentsTrained}
                  <sup>+</sup>
                </div>
              </div>
              <p className="font-bold">STUDENTS TRAINED YEARLY</p>
            </div>
            <div className="bg-blue-700 p-4 lg:p-8">
              <div className="flex items-center justify-center mb-2">
                <Image
                  src="/images/ico-branch.png"
                  className="object-cover mr-2"
                  alt="logo1"
                  width={58}
                  height={58}
                />
                <div className="text-3xl md:text-4xl lg:text-5xl font-bold mt-2">
                  {branches}
                  <sup>+</sup>
                </div>
              </div>
              <p className="font-bold">BRANCHES</p>
            </div>
            <div className="bg-blue-500 p-4 lg:p-8">
              <div className="flex items-center justify-center mb-2">
                <Image
                  src="/images/ico-place.png"
                  className="object-cover mr-2"
                  alt="logo1"
                  width={58}
                  height={58}
                />
                <div className="text-3xl md:text-4xl lg:text-5xl font-bold mt-2">
                  {placements}
                  <sup>+</sup>
                </div>
              </div>
              <p className="font-bold">STUDENTS PLACEMENT DONE</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Counter;
