'use client';
// import { getLatestCourses } from '@/lib/mdxCourses'; // Updated import to reflect courses
import { getLatestCourses } from '@/lib/statamicCourses';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';

const LatestCourses = ({ category = '' }) => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const fetchedCourses = await getLatestCourses({ category });
        setCourses(fetchedCourses);
      } catch (error) {
        console.error('Failed to fetch the latest courses', error);
        setCourses([]);
      }
    };

    fetchCourses();
  }, [category]);

  if (courses && courses?.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <section className="py-8 md:py-10 lg:py-12 relative">
      <div className="max-w-screen-xl mx-5 lg:mx-auto">
        <h3 className="text-center text-color-orange-700 font-semibold">
          Latest Courses
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 items-start justify-between text-center">
          {courses.map((course) => (
            <div
              key={course.id}
              className="bg-white shadow-lg border-b-4 border-b-orange-500 h-full"
            >
              <div className="relative w-full">
                {/* <Image
                  src={course.featured_image || '/images/course/default.jpg'}
                  alt={course.title}
                  width={415}
                  height={225}
                /> */}
                <img
                  src={course.featured_image}
                  alt={course.title}
                  className="w-46 h-46 object-cover rounded-md"
                />
              </div>
              <h3 className="font-bold mb-0 text-xl px-4 h-20 pt-6 text-color-grey-800">
                {course.title}
              </h3>
              <h4 className="mb-0 text-lg mt-2 font-semibold">
                ₹{course.price_with_currency}
              </h4>
              <div className="mb-0 mt-2 text-sm px-4 line-clamp-3">
                {course.excerpt}
              </div>
              <div className="flex flex-col items-center justify-center">
                <Link href={`/courses/${course.slug}`} passHref>
                  <p className="text-sm bg-white hover:bg-orange-600 border border-orange-600 text-orange-600 my-4 hover:text-white font-semibold rounded-md uppercase px-2 py-2 lg:px-4">
                    View Details
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-10">
        <Link href="/courses">
          <p className="hover:bg-orange-600 border border-orange-600 text-black hover:text-white font-semibold rounded-md px-6 py-1 lg:px-8 lg:py-2 text-sm w-48 text-center">
            More Courses
          </p>
        </Link>
      </div>
    </section>
  );
};

export default LatestCourses;
