'use client';
import { useState, useEffect } from 'react';
import Image from 'next/image';
import { Button } from 'flowbite-react';

const Whychoose = () => {
  return (
    <div>
      <section className="bg-white py-16 md:py-20 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            {/* Left Section: Text Content */}
            <div className=" order-2 lg:order-1">
              <div className="mb-10">
                <p className="font-semibold text-red-600 text-base md:text-2xl uppercase">
                  Why Choose Us
                </p>
                <h2 className="font-bold text-black text-2xl lg:text-3xl">
                  Why Be Part of Walkin® Educate
                </h2>
              </div>

              {/* Key Points */}
              <div className="space-y-8">
                <div className="flex items-start">
                  <Image
                    src="/images/ico-tick.jpg"
                    className="object-cover self-start mr-4"
                    width={70}
                    height={70}
                    alt="Tick Icon"
                  />
                  <div>
                    <h5 className="font-semibold text-black text-2xl mb-1">
                      100% Job Assistance Programs
                    </h5>
                    <p className="text-gray-700">
                      All programs include job assistance, and those lasting 6+
                      months guarantee 100% placement assistance*.
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <Image
                    src="/images/ico-tick.jpg"
                    className="object-cover self-start mr-4"
                    width={70}
                    height={70}
                    alt="Tick Icon"
                  />
                  <div>
                    <h5 className="font-semibold text-black text-2xl mb-1">
                      Industry Exposure
                    </h5>
                    <p className="text-gray-700">
                      Our project-based programs include real-world assignments
                      to immerse students in industry-relevant technologies.
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <Image
                    src="/images/ico-tick.jpg"
                    className="object-cover self-start mr-4"
                    width={70}
                    height={70}
                    alt="Tick Icon"
                  />
                  <div>
                    <h5 className="font-semibold text-black text-2xl mb-1">
                      Affordable Fee Structure
                    </h5>
                    <p className="text-gray-700">
                      Competitive fee structure designed to be accessible for
                      students from all backgrounds.
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <Image
                    src="/images/ico-tick.jpg"
                    className="object-cover self-start mr-4"
                    width={70}
                    height={70}
                    alt="Tick Icon"
                  />
                  <div>
                    <h5 className="font-semibold text-black text-2xl mb-1">
                      Certified Trainers
                    </h5>
                    <p className="text-gray-700">
                      Our trainers are certified professionals with over a
                      decade of industry experience and expertise.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Section: Image */}
            <div className="order-1 lg:order-2">
              <Image
                src="/images/img-whychoose.jpg"
                className="object-cover rounded-lg w-auto h-auto lg:h-[600px] mx-auto"
                width={550}
                height={450}
                alt="Why Choose Us"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Whychoose;
