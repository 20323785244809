'use client';

import React from 'react';
import Slider from 'react-slick';
import Image from 'next/image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Faculty = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const images = [
    'walkineducate-annual-gathering.jpeg',
    'walkineducate-expert-session.jpeg',
    'walkineducate-formal-gala-night.jpeg',
    'walkineducate-hobby-awards-ceremony.jpeg',
    'walkineducate-interactive-workshop.jpeg',
    'walkineducate-sports-team-victory.jpeg',
  ];

  return (
    <div>
      <section className="py-10 md:py-16 lg:py-20">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-10 items-center justify-between">
            {/* Slider Section */}
            <div className="md:order-2">
              <Slider {...sliderSettings}>
                {images.map((image, index) => (
                  <div key={index}>
                    <Image
                      alt={`Slide ${index + 1}`}
                      src={`/images/faculty/${image}`}
                      className="object-cover rounded-lg w-full h-[325px] lg:h-[480px]"
                      width={800}
                      height={700}
                    />
                  </div>
                ))}
              </Slider>
            </div>

            {/* Text Section */}
            <div className="md:order-1">
              <h2 className="font-bold text-black text-3xl lg:text-6xl my-3">
                Meet Our Expert Faculty
              </h2>

              <p className="text-base md:text-lg leading-relaxed text-gray-700 mb-6">
                Our faculty are <strong>industry veterans</strong> with
                extensive experience in <strong>design-oriented courses</strong>
                . They are dedicated to mentoring students, guiding them to
                excel in their fields, and helping them secure top placements.
                With their <strong>deep understanding of global trends</strong>,
                our educators ensure students receive training that aligns with
                the latest industry demands.
              </p>

              <p className="text-base md:text-lg leading-relaxed text-gray-700">
                Each faculty member brings unparalleled{' '}
                <strong>expertise and passion</strong> to the classroom,
                enriched by mandatory{' '}
                <strong>certifications, workshops, and seminars</strong>.
                Staying up to date with{' '}
                <strong>cutting-edge tools and methodologies</strong>, they
                deliver courseware designed to meet and exceed{' '}
                <strong>industry standards</strong>. At Walkin Educate, our
                instructors go beyond teaching—they inspire, innovate, and
                empower the next generation of leaders.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faculty;
