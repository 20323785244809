'use client';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image from 'next/image';

const TestimonialSection = () => {
  const testimonials = [
    {
      id: 1,
      text: 'I am having a great time learning with Walkin. The teachers are very understanding and also very helpful.',
      name: 'Disha Shah',
      role: '',
    },
    {
      id: 2,
      text: 'With over a decade of experience, Prof. Aarav specializes in creating industry-ready designers with practical, hands-on training.',
      name: 'Prof. Aarav Joshi',
      role: 'Graphic Design & Animation',
    },
    {
      id: 3,
      text: 'An expert in sustainable and innovative designs, Dr. Meera inspires students to think creatively and eco-consciously.',
      name: 'Dr. Meera Sharma',
      role: 'Interior Design',
    },
    {
      id: 4,
      text: 'Known for his in-depth knowledge of modern web technologies, Prof. Kabir prepares students to excel in the digital age.',
      name: 'Prof. Kabir Malhotra',
      role: 'Web Development',
    },
    {
      id: 5,
      text: 'Ms. Anjali’s expertise in strategy and analytics equips students with the skills to thrive in the competitive marketing landscape.',
      name: 'Ms. Anjali Verma',
      role: 'Digital Marketing',
    },
    {
      id: 6,
      text: 'A celebrated photographer, Mr. Rohan instills creativity and technical mastery in aspiring visual storytellers.',
      name: 'Mr. Rohan Gupta',
      role: 'Photography & Videography',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
  };

  return (
    <section className="relative py-16 lg:py-20 bg-gradient-to-r from-pink-500 via-red-500 to-pink-400">
      {/* Background Design */}
      <div className="absolute inset-0 overflow-hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 800 600"
          fill="none"
          className="opacity-20 w-full h-full"
        >
          <g stroke="white" strokeWidth="0.5">
            <path d="M200 300L400 100M400 100L600 300M600 300L400 500M400 500L200 300" />
            <circle cx="200" cy="300" r="3" />
            <circle cx="400" cy="100" r="3" />
            <circle cx="600" cy="300" r="3" />
            <circle cx="400" cy="500" r="3" />
          </g>
        </svg>
      </div>

      {/* Content */}
      <div className="relative z-10 max-w-3xl mx-auto text-center text-white px-6 lg:px-8">
        <h2 className="text-4xl font-bold mb-6">What People Say?</h2>
        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <div key={testimonial.id}>
              <div className="flex items-center justify-center mb-6">
                <div className="bg-gradient-to-r from-pink-500 via-red-500 to-red-500   p-5 rounded-full shadow-lg">
                  <Image src="/images/quote.png" width={30} height={30} />
                </div>
              </div>
              <p className="text-xl font-light italic mb-6">
                {testimonial.text}
              </p>
              <p className="font-bold text-xl">{testimonial.name}</p>
              <p className="text-sm">{testimonial.role}</p>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default TestimonialSection;
