'use client';
import { useState, useEffect } from 'react';
import Image from 'next/image';
import { Button } from 'flowbite-react';
import CourseCategoriesWidget from '@/components/CourseCategoriesWidget';

const Courses = () => {
  return (
    <div>
      <section className=" pt-20">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-3 md:gap-6 lg:gap-10 items-center justify-between mb-6 md:mb-12">
            <div className="md:col-span-3">
              <h2 className="font-bold text-black my-3">Courses Categories</h2>
              <p>
                Walkin Educate offers a variety of Courses such as Animation,
                Graphic Design, Digital Marketing, Fashion Design, Photography &
                Many More
              </p>
            </div>
            <div className="md:col-span-2 md:text-right">
              <div className="mt-6 md:mt-8">
                <a
                  href="/course-categories"
                  className="btn-theme rounded-full px-6 py-2 lg:px-10 lg:py-4"
                >
                  View All Courses
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CourseCategoriesWidget />
    </div>
  );
};

export default Courses;
