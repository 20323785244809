'use client';

import { useEffect, useState } from 'react';
import { getHomepageCourseCategories } from '@/lib/statamicCourseCategories';
import Link from 'next/link';

export default function CourseCategoriesWidget() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchCategories() {
      setLoading(true);
      const data = await getHomepageCourseCategories();
      setCategories(data);
      setLoading(false);
    }

    fetchCategories();
  }, []);

  if (loading) {
    return <p className="text-center text-gray-500">Loading categories...</p>;
  }

  if (!categories.length) {
    return (
      <p className="text-center text-gray-500">
        No course categories available.
      </p>
    );
  }

  return (
    <div className="bg-white pb-24  p-6">
      <div className="max-w-screen-xl mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {categories.map((category) => (
            <div
              key={category.slug}
              className="bg-gray-100 rounded-lg shadow-md overflow-hidden"
            >
              {/* Featured Image */}
              {category.featured_image && (
                <img
                  src={category.featured_image}
                  alt={category.title}
                  className="w-full h-72 object-cover"
                />
              )}
              <div className="p-4">
                <Link
                  className="text-black"
                  href={`/course-categories/${category.slug}`}
                >
                  <span className="text-lg font-medium  hover:underline">
                    {category.title}
                  </span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
