'use client';
import { useState, useEffect } from 'react';
import Image from 'next/image';
import { Button } from 'flowbite-react';
import Link from 'next/link';

const Infobox = () => {
  return (
    <div>
      <section className="py-10 md:pb-16 lg:pb-20">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 items-stretch justify-between text-center md:-mt-24 lg:-mt-72">
            <div className="bg-white rounded-lg p-6 lg:p-10 border-2 border-gray-400 shadow-2xl z-20">
              <Image
                src="/images/ico-course.png"
                className="object-cover m-auto"
                width={70}
                height={70}
                alt=""
              />
              <h5 className="font-semibold my-3">Career Oriented Courses</h5>
              <p className="h-32">
                Courses available for Graphics and Animation (ADGA), Visual
                Effects (PVFX), Jewellery Design(DJD)
              </p>
              <div>
                <Link
                  href="#"
                  className="btn-theme text-sm rounded-full px-6 py-2 lg:py-3 bg-orange-600 text-white hover:bg-orange-700 transition-all"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="bg-white rounded-lg p-6 lg:p-10 border-2 border-gray-400 shadow-2xl z-20">
              <Image
                src="/images/ico-experts.png"
                className="object-cover m-auto"
                width={70}
                height={70}
                alt=""
              />
              <h5 className="font-semibold my-3">Best Industry Experts</h5>
              <p className="h-32">
                Our experienced staff cater to the trending global needs and
                train the students to excel in the field they are strong in
              </p>
              <div>
                <Link
                  href="#"
                  className="btn-theme text-sm rounded-full px-6 py-2 lg:py-3 bg-orange-600 text-white hover:bg-orange-700 transition-all"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="bg-white rounded-lg p-6 lg:p-10 border-2 border-gray-400 shadow-2xl z-20">
              <Image
                src="/images/ico-placement.png"
                className="object-cover m-auto"
                width={70}
                height={70}
                alt=""
              />
              <h5 className="font-semibold my-3">100% Placement Assistance</h5>
              <p className="h-32">
                Though we give job assistance in all programs all program having
                6+ months duration will have a 100% placement Assistance
              </p>
              <div>
                <Link
                  href="/job-placement"
                  className="btn-theme text-sm rounded-full px-6 py-2 lg:py-3 bg-orange-600 text-white hover:bg-orange-700 transition-all"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Infobox;
