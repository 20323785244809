'use client';
import { useState, useEffect } from 'react';
import Image from 'next/image';
import { Button } from 'flowbite-react';

const Intro = () => {
  return (
    <div>
      <section className="mb-16">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center">
            {/* Left Section: Image */}
            <div className="relative">
              <Image
                alt="About Walkin Educate"
                src="/images/img-about.png"
                className="object-cover z-10"
                width={600}
                height={640}
              />
            </div>

            {/* Right Section: Text Content */}
            <div className="lg:pr-6">
              <p className="font-semibold text-red-600 text-base md:text-lg uppercase">
                About Walkin Educate
              </p>
              <h2 className="font-bold text-black text-2xl lg:text-4xl my-3">
                Revolutionizing Education for a Brighter Future
              </h2>
              <p className="text-base md:text-lg font-medium leading-relaxed">
                At <strong>Walkin Educate</strong>, we redefine learning with
                modern, innovative solutions tailored to empower educational
                institutions and individual learners. Our mission is simple yet
                impactful: make{' '}
                <span className="text-red-600">
                  education accessible, affordable,
                </span>{' '}
                and inclusive for everyone.
              </p>
              <p className="text-base md:text-lg font-medium leading-relaxed mt-4">
                Explore our wide range of courses, from{' '}
                <strong>Animation</strong> and <strong>Graphic Design</strong>{' '}
                to{' '}
                <strong>Digital Marketing, Fashion Design, Photography</strong>,
                and much more. Step into a world where learning meets creativity
                and opportunity.
              </p>
              <div className="mt-6 md:mt-8">
                <a
                  href="/about-us/"
                  className="btn-theme rounded-full px-6 py-2 lg:px-10 lg:py-4 bg-red-600 text-white hover:bg-red-700 transition-all"
                >
                  Learn More About Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Intro;
