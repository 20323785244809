'use client';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image from 'next/image';

const CustomSlider = () => {
  const sliderSettings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Slider {...sliderSettings} className="overflow-hidden">
      <div className="relative w-full h-[780px]">
        <Image
          src="/images/slide1.jpg"
          alt="Slide 1 Background"
          fill
          priority
          className=" object-cover -z-20 before:-z-10"
        />
        <div className="absolute inset-0 max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-0 items-stretch justify-between text-white mt-5 lg:mt-5">
            <div className="py-0 lg:py-24 order-2 lg:order-1">
              <p className="font-medium text-lg md:text-xl my-2">
                Unleash Your Potential. Redefine Your Future
              </p>
              <h1 className="font-bold heading my-2">
                Empowering Creativity and Innovation Through Advanced Education
              </h1>
              <div className="font-medium text-base md:text-lg lg:text-xl">
                Step into the future of creativity and success with
                WalkinEducate, offering industry-aligned diploma and degree
                programs in Graphics, Animation, Fashion, and more. Affordable
                and cutting-edge, our courses empower you with the skills to
                thrive in today's dynamic job market.
              </div>
              <div className="mt-6 md:mt-8">
                <a
                  href="/courses"
                  className="btn-theme px-8 py-3 lg:px-12 lg:py-4 rounded-full"
                >
                  Our Courses
                </a>
              </div>
            </div>
            <div className="relative w-full h-full py-36 md:py-28 order-1 lg:order-2">
              <Image
                src="/images/student4.png"
                alt="Student Image"
                fill
                priority
                className="object-contain"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full h-[780px]">
        <Image
          src="/images/slider-2.jpg"
          alt="Slide 1 Background"
          fill
          className="object-cover"
          priority
        />
        <div className="absolute inset-0 max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-0 items-stretch justify-between text-white mt-5 lg:mt-5">
            <div className="py-0 lg:py-24 order-2 lg:order-1">
              <p className="font-medium text-lg md:text-xl my-2">
                Unleash Your Potential. Redefine Your Future
              </p>
              <h1 className="font-bold heading my-2">
                Empowering Creativity and Innovation Through Advanced Education
              </h1>
              <div className="font-medium text-base md:text-lg lg:text-xl">
                Step into the future of creativity and success with
                WalkinEducate, offering industry-aligned diploma and degree
                programs in Graphics, Animation, Fashion, and more. Affordable
                and cutting-edge, our courses empower you with the skills to
                thrive in today's dynamic job market.
              </div>
              <div className="mt-6 md:mt-8">
                <a
                  href="/courses"
                  className="btn-theme px-8 py-3 lg:px-12 lg:py-4 rounded-full"
                >
                  Our Courses
                </a>
              </div>
            </div>
            <div className="relative w-full h-full py-36 md:py-28 order-1 lg:order-2">
              <Image
                src="/images/student2.png"
                alt="Student Image"
                fill
                className="object-contain object-center"
                priority
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full h-[780px]">
        <Image
          src="/images/slider-2.jpg"
          alt="Slide 1 Background"
          fill
          priority
          className="object-cover"
        />
        <div className="absolute inset-0 max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-0 items-stretch justify-between text-white mt-5 lg:mt-0">
            <div className="py-0  lg:py-24 order-2 lg:order-1">
              <p className="font-medium text-lg md:text-xl my-2">
                Unleash Your Potential. Redefine Your Future
              </p>
              <h1 className="font-bold heading my-2">
                Empowering Creativity and Innovation Through Advanced Education
              </h1>
              <div className="font-medium text-base md:text-lg lg:text-xl">
                Step into the future of creativity and success with
                WalkinEducate, offering industry-aligned diploma and degree
                programs in Graphics, Animation, Fashion, and more. Affordable
                and cutting-edge, our courses empower you with the skills to
                thrive in today's dynamic job market.
              </div>
              <div className="mt-6 md:mt-8">
                <a
                  href="/courses"
                  className="btn-theme px-8 py-3 lg:px-12 lg:py-4 rounded-full"
                >
                  Our Courses
                </a>
              </div>
            </div>
            <div className="relative w-full h-full py-36 md:py-28 order-1 lg:order-2">
              <Image
                src="/images/student3.png"
                alt="Student Image"
                fill
                priority
                className="object-contain"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Add additional slides here if needed */}
    </Slider>
  );
};

export default CustomSlider;
